var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "doc-details-container" },
    _vm._l(_vm.documents, function (document) {
      return _c("div", { key: document.id, staticClass: "doc-details" }, [
        _c(
          "div",
          {
            staticClass: "document-requested-container container",
            attrs: { id: "document-requested" },
          },
          [
            _c("div", { staticClass: "row document-requested-content pt-2" }, [
              _c("div", { staticClass: "col-5" }, [
                _c(
                  "div",
                  { staticClass: "md-2 sm-4 img-container" },
                  [
                    _c("b-img", {
                      staticClass: "document-image",
                      attrs: { src: document.imageUrl, "blank-color": "#bbb" },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-7 document-details" }, [
                _c("p", [_vm._v(" " + _vm._s(document.companyName))]),
                _c("p", [_vm._v(" " + _vm._s(document.jurisdiction))]),
                _c("p", [_vm._v(" " + _vm._s(document.type) + " ")]),
                _c("p", [_vm._v(" " + _vm._s(_vm.createdAt(document)) + " ")]),
              ]),
            ]),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }