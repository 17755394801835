var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "viewDocumentRequestModal",
      attrs: {
        id: "viewDocumentRequestModal",
        title: "Document Requested",
        "title-tag": "h3",
        centered: "",
        size: "md",
        "hide-footer": "",
      },
    },
    [
      !_vm.loading
        ? _c(
            "div",
            [
              _c("doc-details", { attrs: { documents: _vm.documents } }),
              _c(
                "b-card",
                { staticClass: "pb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-3" }, [
                              _c("h5", { staticClass: "card-header-text" }, [
                                _vm._v(
                                  "\n                Mailing\n              "
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-sm-9" },
                              [_c("selected-mailing-address-header")],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "pb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-3" }, [
                              _c("h5", { staticClass: "card-header-text" }, [
                                _vm._v(
                                  "\n                Shipping\n              "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-9" }, [
                              _c(
                                "div",
                                { staticClass: "document-requested-content" },
                                [
                                  _c("p", [
                                    _vm._v(
                                      " " + _vm._s(_vm.shippingType) + " "
                                    ),
                                  ]),
                                  _vm.trackingNumber != null
                                    ? _c(
                                        "p",
                                        [
                                          _vm._v(
                                            "\n                  Tracking:\n                  "
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "mr-1 pt-0",
                                              staticStyle: {
                                                padding: "0px !important",
                                              },
                                              attrs: { variant: "link" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.trackShipping()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(_vm.trackingNumber) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("p", [
                                        _vm._v(
                                          "\n                  Tracking:\n                  "
                                        ),
                                        _c("em", [_vm._v("pending")]),
                                      ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "pb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-3" }, [
                              _c("h5", { staticClass: "card-header-text" }, [
                                _vm._v(
                                  "\n                Payment\n              "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-9" }, [
                              _vm.invoice != null
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "document-requested-content",
                                    },
                                    [
                                      _c(
                                        "p",
                                        [
                                          _vm._v(
                                            "\n                  Invoice:\n                  "
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "mr-1 pt-0",
                                              staticStyle: {
                                                padding: "0px !important",
                                              },
                                              attrs: { variant: "link" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewInvoice()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    #" +
                                                  _vm._s(
                                                    _vm.invoice.invoice_number
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("p", [
                                        _vm._v(
                                          "\n                  Status:\n                  "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "text-transform": "capitalize",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.invoice.status) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "Date Paid: " +
                                            _vm._s(_vm.invoiceDate)
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "Total: " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.invoice.total
                                              )
                                            )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "document-requested-content",
                                    },
                                    [
                                      _c("p", [
                                        _vm._v("No invoice created yet"),
                                      ]),
                                    ]
                                  ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-container", { attrs: { fluid: "" } }, [
                _c(
                  "div",
                  { staticClass: "mb-2 mt-2 text-center" },
                  [
                    _c(
                      "b-button",
                      {
                        class: _vm.$mq !== "sm" ? "col-sm-6" : "col-sm-12",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.closeModal()
                          },
                        },
                      },
                      [_vm._v("\n          Close\n        ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _c("ct-centered-spinner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }