var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "document-requested-content" }, [
    _c("p", [_vm._v(_vm._s(_vm.shippingType) + " ")]),
    _c("p", [_vm._v(_vm._s(_vm.shippingDescription) + " ")]),
    _c("p", [
      _vm._v("\n    Shipping:\n    "),
      _vm.serviceAndShipping.shippingFee === 0
        ? _c("span", { staticClass: "bold positive" }, [
            _vm._v("\n      undetermined\n    "),
          ])
        : _c("span", { staticClass: "bold positive" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("currency")(_vm.serviceAndShipping.shippingFee)) +
                "\n    "
            ),
          ]),
    ]),
    this.serviceAndShipping.shippingDiscount > 0
      ? _c("p", [
          _vm._v("\n    Shipping Discount:\n    "),
          _c("span", { staticClass: "bold negative" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("currency")(_vm.serviceAndShipping.shippingDiscount)
                ) +
                "\n    "
            ),
          ]),
        ])
      : _vm._e(),
    _c("p", [
      _vm._v("\n    Service Fee:\n    "),
      _c("span", { staticClass: "bold positive" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm._f("currency")(_vm.addUpServiceFee)) +
            "\n    "
        ),
      ]),
    ]),
    this.serviceAndShipping.shippingDiscount > 0
      ? _c("p", [
          _vm._v("\n    Service Discount:\n    "),
          _c("span", { staticClass: "bold negative" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("currency")(_vm.serviceAndShipping.serviceDiscount)
                ) +
                "\n    "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }