<template>
  <b-form>
    <back-button position="left" class="float-left" style="background-color:#F8F8F8" @onclick="back()" />

    <doc-details :documents="documents" />

    <b-card no-body class="pb-1">
      <b-container fluid>
        <b-card-header header-tag="header" class="p-1" role="tab">
          <div class="row">
            <div class="col-sm-3">
              <h5 class="card-header-text">
                Mailing
              </h5>
            </div>
            <div class="col-sm-9">
              <selected-mailing-address-header />
            </div>
          </div>
        </b-card-header>
      </b-container>
    </b-card>

    <b-card v-if="!singleDocumentAndIsPackageOrOversize" no-body class="pb-1">
      <b-container fluid>
        <b-card-header header-tag="header" class="p-1" role="tab">
          <div class="row">
            <div class="col-sm-3">
              <h5 class="card-header-text">
                Shipping
              </h5>
            </div>
            <div class="col-sm-9">
              <selected-shipping-header />
            </div>
          </div>
        </b-card-header>
      </b-container>
    </b-card>

    <b-card v-if="!singleDocumentAndIsPackageOrOversize" no-body class="pb-1">
      <b-container fluid>
        <b-card-header header-tag="header" class="p-1" role="tab">
          <div class="row">
            <div class="col-sm-3">
              <h5 class="card-header-text">
                Payment
              </h5>
            </div>
            <div class="col-sm-9">
              <selected-payment-header />
            </div>
          </div>
          <div class="row mt-4" style="margin-bottom: -12px !important;">
            <div class="col-12 d-flex justify-content-center">
              <pay-agreement />
            </div>
          </div>
        </b-card-header>
      </b-container>
    </b-card>

    <b-container fluid>
      <div class="row">
        <div class="col-12 billing-pay mb-2 mt-2">
          <b-button :disabled="!agreementAccepted && !singleDocumentAndIsPackageOrOversize" variant="primary" style="width: 100%" @click="stepComplete()">
            <span v-if="singleDocumentAndIsPackageOrOversize || singleDocumentAndNoShippingQuote">
              Request Document
            </span>
            <span v-else>
              Order Now for {{ serviceAndShipping.serviceTotal + serviceAndShipping.shippingTotal | currency }}
            </span>
          </b-button>
        </div>
      </div>
    </b-container>

    <b-container>
      <help-text />
    </b-container>
  </b-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BackButton, DocDetails, HelpText,  SelectedMailingAddressHeader, SelectedShippingHeader, SelectedPaymentHeader } from './index.js'
import PayAgreement from '@/components/Payments/PayAgreement'

export default {
  name: 'Step3',
  components: {
    BackButton,
    DocDetails,
    HelpText,
    PayAgreement,
    SelectedMailingAddressHeader,
    SelectedShippingHeader,
    SelectedPaymentHeader,
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters('checkout', ['agreementAccepted']),
    ...mapGetters('requestDocument', [
      'documents',
      'serviceAndShipping',
      'singleDocumentAndIsPackageOrOversize',
      'singleDocumentAndNoShippingQuote',
    ]),
  },
  methods: {
    async stepComplete() {
      this.$emit('completed')
    },
    async back() {
      this.$emit('back')
    },
  },
}

</script>

<style scoped>
.billing-pay {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
}
.row {
  margin-bottom: 0.25rem !important;
}
</style>
