<template>
  <b-modal
    id="payInvoiceModal"
    ref="payInvoiceModal"
    class="modal-xl"
    title="Payment"
    title-tag="h3"
    centered
    size="xl"
    @hide="unsetOnClose"
  >
    <b-container
      class="pay-invoices-modal"
      fluid
    >
      <payment-method-options
        :total="selectionTotal"
      />
      <b-form-group>
        <b-form-checkbox
          v-model="manualRemittance"
          class="float-right"
          name="check-button"
        >
          Manual Remittance
        </b-form-checkbox>
      </b-form-group>

      <h5>Invoices</h5>
      <b-list-group v-for="invoice in invoices" :key="invoice.id">
        <b-list-group-item class="d-flex justify-content-between align-items-center" href="#" target="_blank" @click="toggleCollapseFor(invoice.id)">
          <div>
            <p class="mb-1" variant="link">
              {{ invoice.company_name }}
            </p>
            <small class="float-left">#{{ invoice.invoice_number }}</small>
          </div>
          <span><strong>{{ invoice.total | currency }}</strong></span>
        </b-list-group-item>
        <b-collapse :id="collapseIdFor(invoice.id)">
          <b-table :items="invoice.invoice_line_items" :fields="invoiceFields" striped class="pl-lg-3" />
        </b-collapse>
      </b-list-group>
      <br>
      <div class="text-right">
        <h5>
          <strong>Total:</strong>
          {{ selectionTotal | currency }}
        </h5>
      </div>
    </b-container>
    <template v-slot:modal-footer>
      <ct-centered-spinner v-if="paymentLoading" />
      <b-button v-else :disabled="!valid" class="submit-button" aria-label="Submit Button" variant="primary" @click="pay()">
        Submit Payment
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { dateLabel } from '@/directives/directives'
import CtCenteredSpinner from './shared/CtCenteredSpinner'
import PaymentMethodOptions from '@/components/Payments/PaymentMethodOptions'
import { paymentMethodsMixin } from '@/mixins/paymentMethodsMixin'

export default {
  name: 'PayInvoices',
  components: {
    CtCenteredSpinner,
    PaymentMethodOptions,
  },
  directives: {
    dateLabel: dateLabel,
  },
  mixins: [paymentMethodsMixin],
  props: {
    bus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      invoiceIds: [],
      invoiceFields: [
        { key: 'description', class: 'pl-lg-3' },
        { key: 'quantity', class: 'text-center' },
        { key: 'price', formatter: this.formatPrice, class: ['pr-lg-3', 'align-right'] },
      ],
      paymentLoading: false,
      manualRemittance: false,
    }
  },
  computed: {
    ...mapGetters('invoices', ['findAll']),
    selectionTotal() {
      return this.invoices
        .map(i => i.total)
        .reduce((a, b) => {
          return a + b
        }, 0)
    },
    invoices() {
      return this.findAll(this.invoiceIds)
    },
    valid() {
      return this.selectedPaymentMethod && this.agreementAccepted
    },
  },
  async mounted() {
    this.bus.$on('showPayInvoices', this.open)
  },
  methods: {
    ...mapActions('invoices', ['loadIfNotAvailableById']),
    async open(invoiceIds) {
      await this.loadIfNotAvailableById({ ids: invoiceIds })
      this.invoiceIds = invoiceIds

      this.$refs['payInvoiceModal'].show()
    },
    async pay() {
      this.paymentLoading = true
      const ids = this.invoices.map(i => i.id)
      const payableId = this.selectedPaymentMethod.id
      const manualRemittance = this.manualRemittance
      const response = await this.$store.dispatch('invoices/payInvoices', {
        ids,
        payableId,
        manualRemittance,
      })

      this.$refs['payInvoiceModal'].hide()
      if (response.success) {
        await this.$store.dispatch('dashpanel/refresh')
        this.$emit('completed')
      } else {
        const errorMessage = response.data.error.message
        this.$bvToast.toast(errorMessage, {
          title: errorMessage.includes("declined") ? 'Payment method declined' : 'Error',
          variant: 'danger',
          solid: true,
        })
      }
      this.paymentLoading = false
    },
    collapseIdFor(invoiceId) {
      return `v-b-toggle.${invoiceId}`
    },
    toggleCollapseFor(invoiceId) {
      this.$root.$emit('bv::toggle::collapse', this.collapseIdFor(invoiceId))
    },
    formatPrice(value) {
      return this.$options.filters.currency(value)
    },
    unsetOnClose() {
      this.resetPaymentMethod()
      this.resetCurrentTotal()
    },
  },
}
</script>

<style scoped lang="scss">

  .align-right {
    text-align: right;
  }

  .submit-button {
    border-radius: 4px !important;
    padding: 8px 16px 8px 10px !important;
  }

  .pay-invoices-modal {

    // custom striped styling
    ::v-deep(.table-striped) {

      tbody tr:nth-of-type(odd) {
        background: #FFF;
      }

      tbody tr:nth-of-type(even) {
        background: #F5F6F9;
      }
    }
  }
</style>
