<template>
  <div class="document-requested-content">
    <p>{{ shippingType }} </p>
    <p>{{ shippingDescription }} </p>
    <p>
      Shipping:
      <span v-if="serviceAndShipping.shippingFee === 0" class="bold positive">
        undetermined
      </span>
      <span v-else class="bold positive">
        {{ serviceAndShipping.shippingFee | currency }}
      </span>
    </p>
    <p v-if="this.serviceAndShipping.shippingDiscount > 0">
      Shipping Discount:
      <span class="bold negative">
        {{ serviceAndShipping.shippingDiscount | currency }}
      </span>
    </p>
    <p>
      Service Fee:
      <span class="bold positive">
        {{ addUpServiceFee | currency }}
      </span>
    </p>
    <p v-if="this.serviceAndShipping.shippingDiscount > 0">
      Service Discount:
      <span class="bold negative">
        {{ serviceAndShipping.serviceDiscount | currency }}
      </span>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SelectedShippingHeader',
  computed: {
    ...mapGetters('requestDocument', [
      'serviceAndShipping',
      'shippingDescription',
      'shippingType',
      'addUpServiceFee',
    ]),
  },
}
</script>
<style scoped>

  .bold {
    font-weight: bold;
  }

  .positive {
    color: black;
    background: inherit;
  }

  .negative {
    color: red;
    background: inherit;
    font-weight: bold;
  }

</style>
