<template>
  <b-modal
    id="learn-more-documents-modal"
    ref="learn-more-documents-modal"
    title-tag="h3"
    centered
    size="lg"
    hide-footer
    hide-header
  >
    <h3 style="margin: 0; padding: 0" class="text-center">
      Learn More - Documents
    </h3>
    <br>
    <div class="mb-2 text-left">
      <h5 class="sub-title">
        Click To Request
      </h5>
      <hr>
      <ul class="help-content-list">
        <li>
          A <b>physical copy</b> of the document may be requested up to 50 days from receipt. Documents after 50 days are securely destroyed and can no longer be requested.
        </li>
        <li>
          Some document types cannot be requested as they were received as digital copies, and can simply be downloaded and printed from your portal.
        </li>
        <li>
          For requests that cannot be completed, consider downloading and/or printing the document instead.
        </li>
        <li>
          To request multiple documents in one request check the left side box on eligible documents and click the Bundle Multiple Docs button.
        </li>
        <li>
          Requests may require additional charges based off weight and dimensions. Once these fees are calculated an invoice will be generated and you will be notified via email when it is available for payment.
        </li>
        <li>
          Processing time may vary. If you require an urgent shipment, please contact us.
        </li>
      </ul>
    </div>

    <br>
    <div class="text-center">
      <b-button variant="primary" class="mr-2" @click="hidePopup()">
        Close
      </b-button>
    </div>
  </b-modal>
</template>


<script>

export default {
  name: 'LearnMoreDocumentsModal',
  methods: {
    show() {
      this.$refs['learn-more-documents-modal'].show()
    },
    hidePopup() {
      this.$refs['learn-more-documents-modal'].hide()
    },
  },
}
</script>

<style scoped>
ul li {
  padding-bottom: 20px !important;
}
.sub-title {
  margin-left: 1em;
  font-weight: bold;
}
</style>
