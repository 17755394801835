var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "requestDocumentModal",
      attrs: {
        id: "requestDocumentModal",
        title: _vm.modalTitle,
        "title-tag": "h3",
        centered: "",
        size: "lg",
        "hide-footer": "",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
    },
    [
      !_vm.loading
        ? _c(
            "div",
            [
              _vm.step1Active
                ? _c("step-1", {
                    attrs: {
                      bus: _vm.bus,
                      "set-default-values": _vm.setStep1DefaultValues,
                    },
                    on: { completed: _vm.step1Completed },
                  })
                : _vm._e(),
              _vm.step2Active
                ? _c("step-2", {
                    attrs: { bus: _vm.bus },
                    on: { completed: _vm.step2Completed, back: _vm.step2Back },
                  })
                : _vm._e(),
              _vm.step3Active
                ? _c("step-3", {
                    attrs: { bus: _vm.bus },
                    on: { completed: _vm.step3Completed, back: _vm.step3Back },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("ct-centered-spinner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }