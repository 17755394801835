<template>
  <b-modal
    id="viewDocumentRequestModal"
    ref="viewDocumentRequestModal"
    title="Document Requested"
    title-tag="h3"
    centered
    size="md"
    hide-footer
  >
    <div v-if="!loading">
      <doc-details :documents="documents" />

      <b-card no-body class="pb-1">
        <b-container fluid>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <div class="row">
              <div class="col-sm-3">
                <h5 class="card-header-text">
                  Mailing
                </h5>
              </div>
              <div class="col-sm-9">
                <selected-mailing-address-header />
              </div>
            </div>
          </b-card-header>
        </b-container>
      </b-card>

      <b-card no-body class="pb-1">
        <b-container fluid>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <div class="row">
              <div class="col-sm-3">
                <h5 class="card-header-text">
                  Shipping
                </h5>
              </div>
              <div class="col-sm-9">
                <div class="document-requested-content">
                  <p> {{ shippingType }} </p>
                  <p v-if="trackingNumber != null">
                    Tracking:
                    <b-button
                      variant="link"
                      class="mr-1 pt-0"
                      style="padding: 0px !important"
                      @click="trackShipping()"
                    >
                      {{ trackingNumber }}
                    </b-button>
                  </p>
                  <p v-else>
                    Tracking:
                    <em>pending</em>
                  </p>
                </div>
              </div>
            </div>
          </b-card-header>
        </b-container>
      </b-card>

      <b-card no-body class="pb-1">
        <b-container fluid>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <div class="row">
              <div class="col-sm-3">
                <h5 class="card-header-text">
                  Payment
                </h5>
              </div>
              <div class="col-sm-9">
                <div v-if="invoice != null" class="document-requested-content">
                  <p>
                    Invoice:
                    <b-button
                      variant="link"
                      class="mr-1 pt-0"
                      style="padding: 0px !important"
                      @click="viewInvoice()"
                    >
                      #{{ invoice.invoice_number }}
                    </b-button>
                  </p>
                  <p>
                    Status:
                    <span style="text-transform: capitalize">
                      {{ invoice.status }}
                    </span>
                  </p>
                  <p>Date Paid: {{ invoiceDate }}</p>
                  <p>Total: {{ invoice.total | currency }}</p>
                </div>
                <div v-else class="document-requested-content">
                  <p>No invoice created yet</p>
                </div>
              </div>
            </div>
          </b-card-header>
        </b-container>
      </b-card>


      <b-container fluid>
        <div class="mb-2 mt-2 text-center">
          <b-button
            variant="primary"
            :class="$mq !== 'sm' ? 'col-sm-6' : 'col-sm-12'"
            @click="closeModal()"
          >
            Close
          </b-button>
        </div>
      </b-container>
    </div>
    <ct-centered-spinner v-else />
  </b-modal>
</template>

<script>
import DocDetails from '../DocDetails'
import SelectedMailingAddressHeader from '../SelectedMailingAddressHeader'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import { mapGetters } from 'vuex'

export default {
  name: 'ViewDocumentRequestModal',
  components: {
    DocDetails,
    CtCenteredSpinner,
    SelectedMailingAddressHeader,
  },
  props: {
    'bus': Object,
  },
  data: function () {
    return {
      loading: false,
      trackingNumber: null,
      invoice: null,
    }
  },
  computed: {
    ...mapGetters('requestDocument', ['shippingType', 'shippingDescription', 'documents']),
    invoiceDate() {
      const invoice = this.invoice
      let datetime = invoice.paid_at ? invoice.paid_at : invoice.created_at
      let date = new Date(datetime)
      const month = date.toLocaleString('default', { month: 'short' })
      return month + ' ' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + ', ' + date.getFullYear()
    },
  },
  async mounted () {
    this.bus.$on('viewDocumentRequest', this.viewDocumentRequest)
  },
  methods: {
    async viewDocumentRequest (request) {
      if (request.document) {
        this.loading = true
        await this.loadDocumentPDF(request.document)
        this.trackingNumber = request.client_document_request.xps_tracking_number
        this.trackingUrl = request.document.document_tracking_url
        this.invoice = request.invoice
        this.loading = false
        this.$refs.viewDocumentRequestModal.show()
      }
    },
    async loadDocumentPDF (doc) {
      let documentImage = ''
      if (doc.pages) {
        let result = await this.$store.dispatch('documents/getPageURL', {
          id: doc.id,
          pageId: doc.pages[0],
        })
        documentImage = result.data.url
      }
      doc.imageUrl = documentImage
    },
    closeModal () {
      this.$refs.viewDocumentRequestModal.hide()
    },
    trackShipping () {
      window.open(this.trackingUrl, '_blank')
    },
    viewInvoice () {
      this.$router.push({ name: 'invoice', params: { id: this.invoice.id } })
    },
  },
}
</script>

<style lang="scss">
#viewDocumentRequestModal {
  .container {
    text-align: left;
  }

  .modal-body {
    padding: 0 !important;
  }

  .card {
    border-bottom: none;
  }

  .card-body {
    padding: 0 0 1.25rem 0;
  }

  .card-header {
    background-color: white;
    border: none;
    margin: 5px 0 0 0 !important;
  }

  .document-requested-content p {
    margin-bottom: 0;
  }

  .card-header-text {
    font-weight: bold;
  }
}
</style>
