var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "document-requested-content" }, [
    _c("p", [
      _vm._v(
        "\n    " + _vm._s(_vm.selectedMailingAddress.contact_name) + "\n  "
      ),
    ]),
    _c("p", [
      _vm._v(
        "\n    " +
          _vm._s(_vm.selectedMailingAddress.line1) +
          "\n    " +
          _vm._s(_vm.selectedMailingAddress.line2) +
          "\n  "
      ),
    ]),
    _c("p", [
      _vm._v(
        "\n    " +
          _vm._s(_vm.selectedMailingAddress.city) +
          ",\n    " +
          _vm._s(_vm.selectedMailingAddress.state_province_region) +
          "\n    " +
          _vm._s(_vm.selectedMailingAddress.zip_postal_code) +
          "\n    " +
          _vm._s(_vm.selectedMailingAddress.country) +
          "\n  "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }