var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "p",
          { staticStyle: { "text-align": "center", "font-size": "12px" } },
          [
            _c("em", [
              _vm._v(
                "\n        Processing time may vary. If you require an urgent shipment, please contact us.\n      "
              ),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }