<template>
  <b-modal
    id="requestDocumentModal"
    ref="requestDocumentModal"
    :title="modalTitle"
    title-tag="h3"
    centered
    size="lg"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <div v-if="!loading">
      <step-1 v-if="step1Active" :bus="bus" :set-default-values="setStep1DefaultValues" @completed="step1Completed" />
      <step-2 v-if="step2Active" :bus="bus" @completed="step2Completed" @back="step2Back" />
      <step-3 v-if="step3Active" :bus="bus" @completed="step3Completed" @back="step3Back" />
    </div>
    <ct-centered-spinner v-else />
  </b-modal>
</template>


<script>
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RequestDocumentModal',
  components:{
    CtCenteredSpinner,
    Step1, // Select Address and Payment
    Step2, // USPS Address Validation
    Step3,  // Confirmation
  },
  props: {
    bus: Object,
  },
  data: function () {
    return {
      loading: false,
      step1Active: true,
      step2Active: false,
      step3Active: false,
      setStep1DefaultValues: true,
      modalTitle: 'Request Document',
    }
  },
  computed: {
    ...mapGetters('paymentMethods', ['selectedPaymentMethod']),
    ...mapGetters('requestDocument', [
      'documents',
      'selectedMailingAddress',
      'uspsVerifiedAddress',
      'serviceAndShipping',
      'singleDocumentAndIsPackageOrOversize',
    ]),
  },
  async mounted() {
    this.bus.$on('openDocumentRequest', this.openDocumentRequest)
  },
  methods: {
    ...mapActions('requestDocument', [
      'setDocuments',
      'setServiceFee',
      'verifyAddress',
    ]),
    ...mapActions('discounts', ['loadDiscounts']),
    async openDocumentRequest (doc) {
      this.resetData()
      this.setDocuments(doc)
      await this.loadDiscounts()
      this.setServiceFee()
      const promises = this.documents.map((doc) => this.loadDocumentPDF(doc))
      await Promise.allSettled(promises)
      this.$refs.requestDocumentModal.show()
    },
    async loadDocumentPDF(doc) {
      let documentImage = ''
      if (doc.pages) {
        let result = await this.$store.dispatch('documents/getPageURL', {
          id: doc.id,
          pageId: doc.pages[0],
        })
        documentImage = result.data.url
      }
      doc.imageUrl = documentImage
    },
    async step1Completed() {
      this.step1Active = false

      if (this.uspsVerifiedAddress && !this.addressMatchesUspsAddress()) {
        this.showStep2()
      } else {
        this.showStep3()
      }
    },
    step2Completed(addressUpdated) {
      this.step2Active = false
      this.showStep3()

      if (addressUpdated) {
        this.$bvToast.toast('Your Address has been Successfully Updated', {
          title: 'Address Updated',
          variant: 'success',
          solid: true,
          autoHideDelay: 10000,
        })
      }
    },
    async step3Completed() {
      await this.submitDocumentRequest()
    },
    step2Back() {
      this.step2Active = false
      this.setStep1DefaultValues = false
      this.showStep1()
    },
    step3Back() {
      this.step3Active = false
      if (this.uspsVerifiedAddress && !this.addressMatchesUspsAddress()) {
        this.showStep2()
      } else {
        this.setStep1DefaultValues = false
        this.showStep1()
      }
    },
    showStep1() {
      this.step1Active = true
      this.modalTitle = 'Request Document'
    },
    showStep2() {
      this.step2Active = true
      this.modalTitle = 'Address Verification'
    },
    showStep3() {
      this.step3Active = true
      this.modalTitle = 'Order Confirmation'
    },
    resetData() {
      this.step2Active = false
      this.step3Active = false
      this.setStep1InitialLoad = true
      this.showStep1()
    },
    addressMatchesUspsAddress() {
      let subsetCompare = (obj1, obj2, keys) =>  keys.every(key =>  obj1[key] === obj2[key])
      return subsetCompare(this.uspsVerifiedAddress , this.selectedMailingAddress, ['city', 'line1', 'line2', 'state_province_region', 'zip_postal_code'])
    },
    async submitDocumentRequest() {
      this.loading = true

      let response = await this.$store.dispatch('requestDocument/create', {
        ids: this.documents.map(d => d.id),
        toAddress: this.selectedMailingAddress,
        serviceAndShipping: this.serviceAndShipping,
        selectedPayableId: this.singleDocumentAndIsPackageOrOversize ? null : this.selectedPaymentMethod.id,
      })

      if (response && response.result.response.success) {
        this.$emit('completed', this.singleDocumentAndIsPackageOrOversize)
      } else {
        this.$bvToast.toast(response.result.response.error, { title: 'Error', variant: 'danger' })
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
#requestDocumentModal {
  .container {
    text-align: left;
  }

  .modal-body {
    padding: 0 !important;
  }

  .modal-content {
    border-radius: 6px !important;
  }

  .card {
    border-bottom: none;
  }

  .card-body {
    padding: 0 0 1.25rem 0;
  }

  .card-header {
    background-color: white;
    border: none;
    margin: 5px 0 0 0 !important;
  }

  .document-requested-content p {
    margin-bottom: 0;
  }

  .card-header-text{
    font-weight: bold;
  }
}
</style>
