var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "b-form",
        [
          _c("doc-details"),
          _c(
            "div",
            { staticClass: "accordion", attrs: { role: "tablist" } },
            [
              _vm.singleDocumentAndIsPackageOrOversize
                ? _c(
                    "b-card",
                    { staticClass: "pb-1", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "b-card-header",
                            {
                              staticClass: "p-1",
                              attrs: { "header-tag": "header", role: "tab" },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("h5", [
                                    _vm._v(
                                      "This item has been identified as " +
                                        _vm._s(
                                          _vm.documents[0].isOversized
                                            ? "an oversized document"
                                            : "a package"
                                        ) +
                                        "."
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "\n                Please select your mailing address below.\n                After confirmation we will notify you via email when the shipping invoice has been created.\n                Once the invoice is paid the item will be prepared for shipment.\n              "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-card",
                { staticClass: "pb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                class:
                                  _vm.missingMailingAddress ||
                                  _vm.isMailingActive
                                    ? "col-sm-4"
                                    : "col-sm-3",
                              },
                              [
                                _c("h5", { staticClass: "card-header-text" }, [
                                  _vm._v(
                                    "\n                Mailing\n              "
                                  ),
                                ]),
                              ]
                            ),
                            !_vm.missingMailingAddress
                              ? _c(
                                  "div",
                                  {
                                    class: `${
                                      _vm.$mq !== "sm" ? "col-7" : "col-10"
                                    } ${
                                      _vm.isMailingActive ? "panel_active" : ""
                                    }`,
                                  },
                                  [_c("selected-mailing-address-header")],
                                  1
                                )
                              : _vm._e(),
                            _vm.showMailingAddressForm &&
                            _vm.addressOptions.length > 0
                              ? _c(
                                  "div",
                                  {
                                    class:
                                      _vm.$mq !== "sm" ? "col-8" : "col-12",
                                  },
                                  [
                                    _c("back-button", {
                                      attrs: { position: "right" },
                                      on: {
                                        onclick: function ($event) {
                                          return _vm.backToOptions(
                                            "accordion-mailing"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm.missingMailingAddress
                              ? _c(
                                  "div",
                                  {
                                    class:
                                      _vm.$mq !== "sm" ? "col-8" : "col-12",
                                  },
                                  [
                                    _c("add-button", {
                                      class: {
                                        panel_active: _vm.isMailingActive,
                                      },
                                      attrs: { text: "Add Mailing Address" },
                                      on: {
                                        onclick: function ($event) {
                                          return _vm.showAddForm(
                                            "accordion-mailing"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "col-2" },
                                  [
                                    _c("change-button", {
                                      class: {
                                        panel_active: _vm.isMailingActive,
                                      },
                                      on: {
                                        onclick: function ($event) {
                                          return _vm.togglePanel(
                                            "accordion-mailing"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ]),
                        ]
                      ),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion-mailing",
                            accordion: "request-document",
                            role: "tabpanel",
                          },
                        },
                        [
                          _c("b-card-body", [
                            _vm.addressOptions.length > 0 &&
                            !_vm.showMailingAddressForm
                              ? _c(
                                  "div",
                                  { staticClass: "container" },
                                  [
                                    _c(
                                      "b-form-group",
                                      { staticClass: "mb-0" },
                                      _vm._l(
                                        _vm.addressOptions,
                                        function (item) {
                                          return _c(
                                            "b-form-radio",
                                            {
                                              key: item.value.id,
                                              staticClass: "mb-1",
                                              attrs: {
                                                name: "addressRadio",
                                                value: item.value,
                                              },
                                              on: {
                                                change:
                                                  _vm.selectedMailingAddressChanged,
                                              },
                                              model: {
                                                value:
                                                  _vm.mailingAddressRadioValue,
                                                callback: function ($$v) {
                                                  _vm.mailingAddressRadioValue =
                                                    $$v
                                                },
                                                expression:
                                                  "mailingAddressRadioValue",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "container" },
                                                [
                                                  item.value.id == "ADD"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row radio-margin-bottom",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.text
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row radio-margin-bottom",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-10",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.text
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-2",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "edit-link float-right",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.editMailingAddress(
                                                                          item
                                                                            .value
                                                                            .id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          Edit\n                        "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "text-center" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "mt-2",
                                            class:
                                              _vm.$mq !== "sm"
                                                ? "col-sm-6"
                                                : "col-sm-12",
                                            attrs: { variant: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.closeSection(
                                                  "accordion-mailing"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Continue\n                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showMailingAddressForm
                              ? _c(
                                  "div",
                                  [
                                    _c("address-form", {
                                      attrs: {
                                        "selected-address":
                                          _vm.selectedMailingAddress.id == "ADD"
                                            ? null
                                            : _vm.selectedMailingAddress,
                                        type:
                                          _vm.selectedMailingAddress.id == "ADD"
                                            ? "add"
                                            : "edit",
                                      },
                                      on: {
                                        completed: _vm.updateMailingAddress,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.documentsDoNotContainPackageOrOversize
                ? _c(
                    "b-card",
                    { staticClass: "pb-1", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "b-card-header",
                            {
                              staticClass: "p-1",
                              attrs: { "header-tag": "header", role: "tab" },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-3" }, [
                                  _c(
                                    "h5",
                                    { staticClass: "card-header-text" },
                                    [
                                      _vm._v(
                                        "\n                Shipping\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9" },
                                  [_c("selected-shipping-header")],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.documentsDoNotContainPackageOrOversize
                ? _c(
                    "b-card",
                    { staticClass: "pb-1", attrs: { "no-body": "" } },
                    [
                      _c("select-payment-method", {
                        attrs: { "is-billing-active": _vm.isBillingActive },
                        on: {
                          closeSection: function ($event) {
                            return _vm.closeSection("accordion-billing")
                          },
                          togglePanel: function ($event) {
                            return _vm.togglePanel("accordion-billing")
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("b-container", { attrs: { fluid: "" } }, [
            _c(
              "div",
              {
                staticClass: "row",
                class: {
                  panel_active: _vm.isBillingActive || _vm.isMailingActive,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "col-12 text-center mb-2 mt-2" },
                  [
                    _c(
                      "div",
                      { attrs: { id: "btn-submit" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-3",
                            class: _vm.$mq !== "sm" ? "col-sm-6" : "col-sm-12",
                            attrs: {
                              disabled: _vm.formInvalid,
                              variant: "primary",
                            },
                            on: { click: _vm.handleContinue },
                          },
                          [_vm._v("\n            Continue\n          ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          disabled: !_vm.formInvalid,
                          target: "btn-submit",
                          placement: "top",
                          triggers: "hover focus",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.formInvalidText) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          title: "Confirm Request",
                          "cancel-title": "Return",
                          "ok-title": "Proceed Anyway",
                        },
                        on: { ok: _vm.submitForm },
                        model: {
                          value: _vm.showConfirmationModal,
                          callback: function ($$v) {
                            _vm.showConfirmationModal = $$v
                          },
                          expression: "showConfirmationModal",
                        },
                      },
                      [
                        _c("div", { staticClass: "text-center" }, [
                          _vm.serviceAndShipping.error
                            ? _c("p", [
                                _vm._v(
                                  "\n              We were unable to get a shipping quote due to: " +
                                    _vm._s(_vm.serviceAndShipping.error) +
                                    "\n            "
                                ),
                              ])
                            : _c("p", [
                                _vm._v(
                                  "\n              We were unable to get a shipping quote.\n            "
                                ),
                              ]),
                          _c("p", [
                            _vm._v(
                              "\n              You may want to edit the mailing address and try again.\n              If you proceed without a quote, we will notify you via email\n              if we are able to get one and create an invoice.\n            "
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("b-container", [_c("help-text")], 1),
          _c("payment-ssl"),
        ],
        1
      )
    : _c("ct-centered-spinner")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }