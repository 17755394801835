var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "b-form",
        [
          _c("back-button", {
            staticClass: "float-left mt-2 mb-2",
            attrs: { position: "left" },
            on: {
              onclick: function ($event) {
                return _vm.back()
              },
            },
          }),
          _c("b-container", { attrs: { fluid: "" } }, [
            _c("p", { staticClass: "text-center" }, [
              _vm._v(
                "\n      We found a verified USPS format for your Mailing Address.\n    "
              ),
            ]),
            _c("p", { attrs: { id: "addr-verification-text" } }, [
              _vm._v(
                "\n      Select which Address you would like to use:\n    "
              ),
            ]),
            _c("input", {
              staticClass: "updateAddress",
              attrs: {
                id: "updateAddressNo",
                type: "radio",
                checked: "",
                name: "updateAddress",
                value: "no",
              },
            }),
            _c(
              "label",
              {
                staticClass: "address-verification-radio-box",
                attrs: { for: "updateAddressNo" },
              },
              [
                _c("p", { staticClass: "header" }, [_vm._v("You Entered")]),
                _c("p", { staticClass: "line-item" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.selectedMailingAddress.line1) +
                      "\n        " +
                      _vm._s(_vm.selectedMailingAddress.line2) +
                      "\n      "
                  ),
                ]),
                _c("p", { staticClass: "line-item" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.selectedMailingAddress.city) +
                      ",\n        " +
                      _vm._s(_vm.selectedMailingAddress.state_province_region) +
                      "\n        " +
                      _vm._s(_vm.selectedMailingAddress.zip_postal_code) +
                      "\n      "
                  ),
                ]),
              ]
            ),
            _c("input", {
              staticClass: "updateAddress",
              attrs: {
                id: "updateAddressYes",
                type: "radio",
                name: "updateAddress",
                value: "yes",
              },
            }),
            _c(
              "label",
              {
                staticClass: "address-verification-radio-box",
                attrs: { for: "updateAddressYes" },
              },
              [
                _c("p", { staticClass: "header" }, [_vm._v("USPS Verified")]),
                _c(
                  "p",
                  { staticClass: "line-item", attrs: { id: "uspsAddress2" } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.uspsVerifiedAddress.line1) +
                        "\n        " +
                        _vm._s(_vm.uspsVerifiedAddress.line2) +
                        "\n      "
                    ),
                  ]
                ),
                _c("p", { staticClass: "line-item" }, [
                  _c("span", { attrs: { id: "uspsCity" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.uspsVerifiedAddress.city) +
                        ",\n        "
                    ),
                  ]),
                  _c("span", { attrs: { id: "uspsState" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.uspsVerifiedAddress.state_province_region) +
                        "\n        "
                    ),
                  ]),
                  _c("span", { attrs: { id: "uspsZip" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.uspsVerifiedAddress.zip_postal_code) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c("b-container", [
            _c(
              "div",
              { staticClass: "mb-4 mt-2 text-center" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-3",
                    class: _vm.$mq !== "sm" ? "col-sm-6" : "col-sm-12",
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.stepComplete()
                      },
                    },
                  },
                  [_vm._v("\n        Continue\n      ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _c("ct-centered-spinner")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }