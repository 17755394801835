var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c("div", [
        _vm.type == "add"
          ? _c(
              "form",
              {
                ref: "form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveContact.apply(null, arguments)
                  },
                },
              },
              [
                _c("validation-observer", {
                  ref: "observer",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ invalid }) {
                          return [
                            _c("p", { staticClass: "text-center" }, [
                              _c("em", [
                                _vm._v(
                                  "This will add a new contact to your account."
                                ),
                              ]),
                            ]),
                            _c(
                              "b-form-row",
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "col-6" },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "First Name:" } },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            rules: "required",
                                            name: "Name",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Enter first name",
                                                        type: "text",
                                                        state:
                                                          errors.length > 0
                                                            ? !errors[0]
                                                            : null,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentContact
                                                            .first_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentContact,
                                                            "first_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentContact.first_name",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { staticClass: "col-6" },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "Last Name:" } },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            rules: "required",
                                            name: "Name",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Enter last name",
                                                        type: "text",
                                                        state:
                                                          errors.length > 0
                                                            ? !errors[0]
                                                            : null,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentContact
                                                            .last_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentContact,
                                                            "last_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentContact.last_name",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-form-row",
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "col-6" },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "Email:" } },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            rules: "required|email",
                                            name: "Email",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Enter an email",
                                                        type: "text",
                                                        state:
                                                          errors.length > 0
                                                            ? !errors[0]
                                                            : null,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentContact
                                                            .person_emails[0]
                                                            .email_address,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentContact
                                                              .person_emails[0],
                                                            "email_address",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentContact.person_emails[0].email_address",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { staticClass: "col-6" },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "Phone number:" } },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            rules: "required|phone_number",
                                            name: "Phone",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("b-form-input", {
                                                      directives: [
                                                        {
                                                          name: "mask",
                                                          rawName: "v-mask",
                                                          value: "###########",
                                                          expression:
                                                            "'###########'",
                                                        },
                                                      ],
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        placeholder:
                                                          "Enter a phone number",
                                                        state:
                                                          errors.length > 0
                                                            ? !errors[0]
                                                            : null,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentContact
                                                            .person_phones[0]
                                                            .phone_number,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentContact
                                                              .person_phones[0],
                                                            "phone_number",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentContact.person_phones[0].phone_number",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("address-form", {
                              staticStyle: { padding: "0 !important" },
                              attrs: {
                                "edit-address":
                                  _vm.currentContact.person_addresses[0],
                                "allow-existing-addresses": false,
                                "show-contact-name": false,
                                kind: "mailing",
                              },
                              on: { changed: _vm.addressInfoChanged },
                            }),
                            _c(
                              "b-form-row",
                              { staticClass: "justify-content-center" },
                              [
                                _c("div", { attrs: { id: "btn-submit" } }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-primary submit-btn-width",
                                      staticStyle: { "pointer-events": "auto" },
                                      attrs: {
                                        type: "submit",
                                        disabled: invalid,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            Continue\n          "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            invalid
                              ? _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      target: "btn-submit",
                                      placement: "top",
                                      triggers: "hover focus",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n        Please fill out the rest of the form.\n      "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2463831360
                  ),
                }),
              ],
              1
            )
          : _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.updateContact.apply(null, arguments)
                  },
                },
              },
              [
                _vm._m(0),
                _c("address-form", {
                  staticStyle: { padding: "0 !important" },
                  attrs: {
                    "edit-address": _vm.selectedAddress,
                    "show-contact-name": true,
                    "contact-name-readonly": true,
                  },
                  on: { changed: _vm.addressInfoChanged },
                }),
                _c("b-form-row", { staticClass: "justify-content-center" }, [
                  _c("div", { attrs: { id: "btn-submit-edit" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary submit-btn-width",
                        staticStyle: { "pointer-events": "auto" },
                        attrs: { type: "submit" },
                      },
                      [_vm._v("\n          Continue\n        ")]
                    ),
                  ]),
                ]),
              ],
              1
            ),
      ])
    : _c("ct-centered-spinner")
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-center" }, [
      _c("em", [
        _vm._v("This will update the contacts address on your account."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }