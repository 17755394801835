<template>
  <div class="row">
    <div class="col-12">
      <p style="text-align: center; font-size: 12px">
        <em>
          Processing time may vary. If you require an urgent shipment, please contact us.
        </em>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HelpText',
}
</script>
