<template>
  <div class="doc-details-container">
    <div v-for="document in documents" :key="document.id" class="doc-details">
      <div id="document-requested" class="document-requested-container container">
        <div class="row document-requested-content  pt-2">
          <div class="col-5">
            <div class="md-2 sm-4 img-container">
              <b-img :src="document.imageUrl" blank-color="#bbb" class="document-image" />
            </div>
          </div>
          <div class="col-7 document-details">
            <p> {{ document.companyName }}</p>
            <p> {{ document.jurisdiction }}</p>
            <p> {{ document.type }} </p>
            <p> {{ createdAt(document) }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateString } from '@/common/modules/formatters'
import { mapGetters } from 'vuex'

export default {
  name: 'DocDetails',
  computed: {
    ...mapGetters('requestDocument', [
      'documents',
    ]),
  },
  methods: {
    formatDate: formatDateString,
    createdAt(document) {
      let approvedAt = document.approvedAt || document.approved_at
      return this.formatDate(approvedAt)
    },
  },
}

</script>

<style scoped>
.document-image {
  border: solid 1px lightgray;
  box-shadow: 0 4px 8px 0 lightgrey, 0 4px 12px 0 lightgrey;
  width: 100%;
}

.document-details p {
  padding: 5px 0;
}

.document-requested-content {
  background-color: #F8F8F8;
}

.row {
  padding-bottom: 1em;
  margin-bottom: 0 !important;
}
.doc-details-container {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  background-color: #F8F8F8;
}
.doc-details {
  min-width: 90%;
}
</style>
