<template>
  <b-form v-if="!loading">
    <doc-details />

    <div class="accordion" role="tablist">
      <b-card v-if="singleDocumentAndIsPackageOrOversize" no-body class="pb-1">
        <b-container fluid>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <div class="row">
              <div class="col-12">
                <h5>This item has been identified as {{ documents[0].isOversized ? 'an oversized document' : 'a package' }}.</h5>
                <p>
                  Please select your mailing address below.
                  After confirmation we will notify you via email when the shipping invoice has been created.
                  Once the invoice is paid the item will be prepared for shipment.
                </p>
              </div>
            </div>
          </b-card-header>
        </b-container>
      </b-card>
      <b-card no-body class="pb-1">
        <b-container fluid>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <div class="row">
              <div :class="missingMailingAddress || isMailingActive ? 'col-sm-4' : 'col-sm-3'">
                <h5 class="card-header-text">
                  Mailing
                </h5>
              </div>

              <div v-if="!missingMailingAddress" :class="`${$mq !== 'sm' ? 'col-7' : 'col-10'} ${ isMailingActive ? 'panel_active' : ''}`">
                <selected-mailing-address-header />
              </div>

              <div v-if="showMailingAddressForm && addressOptions.length > 0" :class="$mq !== 'sm' ? 'col-8' : 'col-12'">
                <back-button position="right" @onclick="backToOptions('accordion-mailing')" />
              </div>
              <div v-else-if="missingMailingAddress" :class="$mq !== 'sm' ? 'col-8' : 'col-12'">
                <add-button
                  text="Add Mailing Address"
                  :class="{ panel_active: isMailingActive }"
                  @onclick="showAddForm('accordion-mailing')"
                />
              </div>
              <div v-else class="col-2">
                <change-button
                  :class="{ panel_active: isMailingActive }"
                  @onclick="togglePanel('accordion-mailing')"
                />
              </div>
            </div>
          </b-card-header>

          <b-collapse id="accordion-mailing" accordion="request-document" role="tabpanel">
            <b-card-body>
              <div v-if="addressOptions.length > 0 && !showMailingAddressForm" class="container">
                <b-form-group class="mb-0">
                  <b-form-radio
                    v-for="item in addressOptions"
                    :key="item.value.id"
                    v-model="mailingAddressRadioValue"
                    name="addressRadio"
                    :value="item.value"
                    class="mb-1"
                    @change="selectedMailingAddressChanged"
                  >
                    <div class="container">
                      <div v-if="item.value.id == 'ADD'" class="row radio-margin-bottom">
                        <div class="col-12">
                          {{ item.text }}
                        </div>
                      </div>
                      <div v-else class="row radio-margin-bottom">
                        <div class="col-10">
                          {{ item.text }}
                        </div>
                        <div class="col-2">
                          <a class="edit-link float-right" @click="editMailingAddress(item.value.id)">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                  </b-form-radio>
                </b-form-group>
                <div class="text-center">
                  <b-button
                    class="mt-2"
                    variant="primary"
                    :class="$mq !== 'sm' ? 'col-sm-6' : 'col-sm-12'"
                    @click="closeSection('accordion-mailing')"
                  >
                    Continue
                  </b-button>
                </div>
              </div>
              <div v-if="showMailingAddressForm">
                <address-form
                  :selected-address="selectedMailingAddress.id == 'ADD' ? null : selectedMailingAddress"
                  :type="selectedMailingAddress.id == 'ADD' ? 'add' : 'edit'"
                  @completed="updateMailingAddress"
                />
              </div>
            </b-card-body>
          </b-collapse>
        </b-container>
      </b-card>

      <b-card v-if="documentsDoNotContainPackageOrOversize" no-body class="pb-1">
        <b-container fluid>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <div class="row">
              <div class="col-sm-3">
                <h5 class="card-header-text">
                  Shipping
                </h5>
              </div>
              <div class="col-sm-9">
                <selected-shipping-header />
              </div>
            </div>
          </b-card-header>
        </b-container>
      </b-card>

      <b-card v-if="documentsDoNotContainPackageOrOversize" no-body class="pb-1">
        <select-payment-method
          :is-billing-active="isBillingActive"
          @closeSection="closeSection('accordion-billing')"
          @togglePanel="togglePanel('accordion-billing')"
        />
      </b-card>
    </div>

    <b-container fluid>
      <div class="row" :class="{ panel_active: isBillingActive || isMailingActive }">
        <div class="col-12 text-center mb-2 mt-2">
          <div id="btn-submit">
            <b-button
              :disabled="formInvalid"
              variant="primary"
              class="mr-3"
              :class="$mq !== 'sm' ? 'col-sm-6' : 'col-sm-12'"
              @click="handleContinue"
            >
              Continue
            </b-button>
          </div>

          <b-tooltip
            :disabled="!formInvalid"
            target="btn-submit"
            placement="top"
            triggers="hover focus"
          >
            {{ formInvalidText }}
          </b-tooltip>

          <b-modal
            v-model="showConfirmationModal"
            title="Confirm Request"
            :cancel-title="'Return'"
            :ok-title="'Proceed Anyway'"
            @ok="submitForm"
          >
            <div class="text-center">
              <p v-if="serviceAndShipping.error">
                We were unable to get a shipping quote due to: {{ serviceAndShipping.error }}
              </p>
              <p v-else>
                We were unable to get a shipping quote.
              </p>
              <p>
                You may want to edit the mailing address and try again.
                If you proceed without a quote, we will notify you via email
                if we are able to get one and create an invoice.
              </p>
            </div>
          </b-modal>
        </div>
      </div>
    </b-container>

    <b-container>
      <help-text />
    </b-container>
    <payment-ssl />
  </b-form>
  <ct-centered-spinner v-else />
</template>


<script>

import { mapGetters, mapActions } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import { AddButton, AddressForm, BackButton, ChangeButton, DocDetails, SelectedMailingAddressHeader, SelectedShippingHeader, SelectPaymentMethod, HelpText } from './index.js'
import { extend } from 'vee-validate'
import { email, required } from 'vee-validate/dist/rules'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import PaymentSsl from '@/components/shared/PaymentSsl.vue'


extend('email', email)
extend('required', {
  ...required,
  message: 'This field is required',
})

export default {
  name: 'Step1',
  components: {
    AddButton,
    AddressForm,
    BackButton,
    CtCenteredSpinner,
    ChangeButton,
    DocDetails,
    HelpText,
    SelectedMailingAddressHeader,
    SelectedShippingHeader,
    SelectPaymentMethod,
    PaymentSsl,
  },
  mixins: [makeToastMixin],
  props: {
    bus: null,
    setDefaultValues:{
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      loading: true,
      isMailingActive: false,
      isBillingActive: false,
      editSelectedMailingAddress: false,
      hideSubmitRequest: false,
      addressOptions: [],
      addresses: [],
      showMailingAddressForm: false,
      mailingAddressRadioValue: null,
      showConfirmationModal: false,
    }
  },
  computed: {
    ...mapGetters('paymentMethods', ['selectedPaymentMethod']),
    ...mapGetters('requestDocument', [
      'documents',
      'selectedMailingAddress',
      'shippingType',
      'serviceAndShipping',
      'singleDocumentAndIsPackageOrOversize',
    ]),
    ...mapGetters('session', [
      'getToken',
    ]),
    documentsDoNotContainPackageOrOversize() {
      return (this.documents.filter((d) => d.isPackage || d.isOversized).length < 1)
    },
    missingMailingAddress() {
      return (this.selectedMailingAddress == null || this.selectedMailingAddress.id == 'ADD')
    },
    missingPaymentMethod() {
      return (this.documentsDoNotContainPackageOrOversize && (this.selectedPaymentMethod == null || this.selectedPaymentMethod.id == 'ADD'))
    },
    invalidDocument() {
      return (this.documents.filter((d) => d.pages == null || d.pages < 1).length > 0 )
    },
    formInvalid() {
      return (this.invalidDocument || this.missingMailingAddress || this.missingPaymentMethod)
    },
    formInvalidText() {
      let invalidText = ''
      if (this.invalidDocument) {
        invalidText = 'The document is not valid.'
      } else if (this.missingMailingAddress) {
        invalidText = 'Mailing Address is required'
      } else if (this.missingPaymentMethod) {
        invalidText = 'Payment Method is required'
      }
      return invalidText
    },
  },
  async mounted() {
    if (this.documents.length) {
      this.resetActivePanel()
      this.loadAddresses()
      if (this.setDefaultValues) {
        this.loadDefaultAddress()
      } else {
        this.mailingAddressRadioValue = this.selectedMailingAddress
      }
      await this.calculateShippingMethod()
      await this.getShippingDiscount()
      await this.getServiceDiscount()
    }
    this.loading = false
  },

  methods: {
    ...mapActions('requestDocument', [
      'setSelectedMailingAddress',
      'calculateShipping',
      'setServiceDiscount',
      'setShippingDiscount',
      'verifyAddress',
    ]),
    resetActivePanel() {
      this.isMailingActive = false
      this.isBillingActive = false
      this.editSelectedMailingAddress = false
      this.hideSubmitRequest = false
      this.showMailingAddressForm = false
    },
    loadAddresses() {
      this.addresses = []
      this.addressOptions = []
      let people = this.$store.getters['account/people']
      this.addresses = people.map(person => person.person_addresses).flat()

      if (this.addresses !== undefined && this.addresses.length > 0) {
        this.addressOptions = this.addresses.map(a => {
          const addressLine = a.line2 != null ? a.line1 + ' ' + a.line2 : a.line1
          return [{
            text: `${a.contact_name} (${addressLine}, ${a.city}, ${a.state_province_region} ${a.zip_postal_code}, ${a.country})`,
            value: a,
          }]
        }).flat()

        this.addressOptions.push(
          {
            value: {
              'id': 'ADD',
              'contact_name': null,
              'line1': null,
              'line2': null,
              'city': null,
              'state_province_region': null,
              'zip_postal_code': null,
              'country': null,
            },
            text: 'Add new address',
          })
      }
    },
    loadDefaultAddress() {
      let defaultAddress = { 'id': 'ADD' }
      if (this.addresses !== undefined && this.addresses.length > 0) {
        defaultAddress = this.addresses[0]
        const mailingAddress = this.addresses.find(a => a.kind === 'mailing')
        if (mailingAddress) {
          defaultAddress = mailingAddress
        } else {
          const primaryAddress = this.addresses.find(a => a.kind === 'primary')
          if (primaryAddress) {
            defaultAddress = primaryAddress
          }
        }
      }
      this.setMailingAddress(defaultAddress)
    },
    setMailingAddress(address) {
      this.mailingAddressRadioValue = address
      this.setSelectedMailingAddress(address)
    },
    async calculateShippingMethod() {
      if (this.selectedMailingAddress && this.selectedMailingAddress.id !== 'ADD') {
        const payload = {
          ids: this.documents.map(d => d.id),
          toAddress: this.selectedMailingAddress,
        }

        const response = await this.calculateShipping(payload)

        if (typeof (response) === 'undefined' || response === null) {
          this.errorToast('Unable to load shipping')
        }

        if (response && response['quote'] && response['quote'] === 0.00) {
          this.warningToast(
            'Mail delivery service is not available to this address',
            'Please select another address or contact us for assistance',
            5000
          )
        }
      }
    },
    async getShippingDiscount() {
      await this.setShippingDiscount()
    },
    async getServiceDiscount() {
      await this.setServiceDiscount()
    },
    togglePanel(accordionName) {
      this.resetActivePanel()

      if (accordionName == 'accordion-mailing') {
        this.isMailingActive = true
        this.$root.$emit('bv::toggle::collapse', accordionName)
      } else {
        this.isBillingActive = true
      }
      this.hideSubmitRequest = true
    },
    selectedMailingAddressChanged(address) {
      this.setMailingAddress(address)
      if (address.id == 'ADD' || this.editSelectedMailingAddress) {
        this.showMailingAddressForm = true
      }
    },
    backToOptions(accordionName) {
      if (accordionName == 'accordion-mailing') {
        this.editSelectedMailingAddress = false
        this.showMailingAddressForm = false
      }
    },
    showAddForm(accordionName) {
      this.togglePanel(accordionName)
      if (accordionName == 'accordion-mailing') {
        this.showMailingAddressForm = true
      }
    },
    async closeSection(accordionName) {
      if (accordionName == 'accordion-mailing') {
        await this.calculateShippingMethod()
        this.$root.$emit('bv::toggle::collapse', accordionName)
      }
      this.resetActivePanel()
    },
    errorToast (error) {
      this.$bvToast.toast(error, { title: 'Error', variant: 'danger' })
    },
    editMailingAddress(addressId) {
      this.editSelectedMailingAddress = true

      if (addressId == this.selectedMailingAddress.id) {
        this.showMailingAddressForm = true
      }
    },
    async updateMailingAddress(address) {
      this.showMailingAddressForm = false
      let editMailingAddress = this.editSelectedMailingAddress
      await this.loadAddresses()

      let body = ''
      let title = ''
      let variant = 'success'

      if (editMailingAddress) {
        if (!address) {
          title = 'Couldn\'t Update Address'
          body = 'There was an issue updating your Mailing Address.'
          variant = 'danger'
        } else {
          title = 'Address Updated'
          body = 'Your Address has been Successfully Updated'
          this.setMailingAddress(this.addresses.find(a => a.id === address.id))
        }
      } else {
        if (!this.addresses.some(c => c.id === address.id)) {
          title = 'Couldn\'t Add Contact'
          body = 'There was an issue creating your the contact.'
          variant = 'danger'
        } else {
          title = 'Contact Created'
          body = 'Your Contact has been Successfully Created'
          this.setMailingAddress(this.addresses.find(a => a.id === address.id))
        }
      }
      await this.closeSection('accordion-mailing')

      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: 10000,
      })
    },
    async validateAddress(address) {
      await this.verifyAddress( {
        id: this.documents[0].id,
        toAddress: address,
      })
    },
    async handleContinue() {
      if (this.serviceAndShipping.shippingFee === 0.00) {
        this.showConfirmationModal = true
      } else {
        await this.submitForm()
      }
    },
    async submitForm() {
      await this.stepComplete()
    },
    closeConfirmationModal() {
      this.showConfirmationModal = false
    },
    async stepComplete() {
      this.loading = true

      if (this.invalidDocument) {
        this.errorToast('Unable to process the request, the document is not valid. Please contact support')
      } else if (this.missingMailingAddress) {
        this.errorToast('Mailing Address is required')
      } else if (this.missingPaymentMethod) {
        this.errorToast('Payment Method is required')
      } else {
        await this.validateAddress(this.selectedMailingAddress)
        this.$emit('completed')
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.panel_active {
  display: none;
}

.edit-link {
  color: #007bff !important;
  cursor: pointer;
}

.edit-link:hover {
  text-decoration: underline !important;
}

* >>> .custom-control-label {
  width: 100% !important;
}

.radio-margin-bottom {
  margin-bottom: .25rem !important;
}

.row {
  margin-bottom: 0.25rem !important;
}

.error-message {
  color: red;
  font-size: 12px;
}
.payment-ssl-badge {
  width: 5.5em;
  margin: 0px auto 0.625em;
}
</style>
