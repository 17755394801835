<template>
  <div class="document-requested-content">
    <p>
      {{ selectedMailingAddress.contact_name }}
    </p>
    <p>
      {{ selectedMailingAddress.line1 }}
      {{ selectedMailingAddress.line2 }}
    </p>
    <p>
      {{ selectedMailingAddress.city }},
      {{ selectedMailingAddress.state_province_region }}
      {{ selectedMailingAddress.zip_postal_code }}
      {{ selectedMailingAddress.country }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SelectedMailingAddressHeader',
  computed: {
    ...mapGetters('requestDocument', ['selectedMailingAddress']),
  },
}
</script>
