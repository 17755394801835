var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    [
      _c("back-button", {
        staticClass: "float-left",
        staticStyle: { "background-color": "#F8F8F8" },
        attrs: { position: "left" },
        on: {
          onclick: function ($event) {
            return _vm.back()
          },
        },
      }),
      _c("doc-details", { attrs: { documents: _vm.documents } }),
      _c(
        "b-card",
        { staticClass: "pb-1", attrs: { "no-body": "" } },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-card-header",
                {
                  staticClass: "p-1",
                  attrs: { "header-tag": "header", role: "tab" },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c("h5", { staticClass: "card-header-text" }, [
                        _vm._v("\n              Mailing\n            "),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-9" },
                      [_c("selected-mailing-address-header")],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.singleDocumentAndIsPackageOrOversize
        ? _c(
            "b-card",
            { staticClass: "pb-1", attrs: { "no-body": "" } },
            [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c("h5", { staticClass: "card-header-text" }, [
                            _vm._v("\n              Shipping\n            "),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-sm-9" },
                          [_c("selected-shipping-header")],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.singleDocumentAndIsPackageOrOversize
        ? _c(
            "b-card",
            { staticClass: "pb-1", attrs: { "no-body": "" } },
            [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c("h5", { staticClass: "card-header-text" }, [
                            _vm._v("\n              Payment\n            "),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-sm-9" },
                          [_c("selected-payment-header")],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "row mt-4",
                          staticStyle: { "margin-bottom": "-12px !important" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-center",
                            },
                            [_c("pay-agreement")],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("b-container", { attrs: { fluid: "" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 billing-pay mb-2 mt-2" },
            [
              _c(
                "b-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled:
                      !_vm.agreementAccepted &&
                      !_vm.singleDocumentAndIsPackageOrOversize,
                    variant: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.stepComplete()
                    },
                  },
                },
                [
                  _vm.singleDocumentAndIsPackageOrOversize ||
                  _vm.singleDocumentAndNoShippingQuote
                    ? _c("span", [
                        _vm._v("\n            Request Document\n          "),
                      ])
                    : _c("span", [
                        _vm._v(
                          "\n            Order Now for " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.serviceAndShipping.serviceTotal +
                                  _vm.serviceAndShipping.shippingTotal
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("b-container", [_c("help-text")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }