<template>
  <b-form v-if="!loading">
    <back-button position="left" class="float-left mt-2 mb-2" @onclick="back()" />
    <b-container fluid>
      <p class="text-center">
        We found a verified USPS format for your Mailing Address.
      </p>

      <p id="addr-verification-text">
        Select which Address you would like to use:
      </p>

      <input
        id="updateAddressNo"
        type="radio"
        checked
        name="updateAddress"
        class="updateAddress"
        value="no"
      >

      <label class="address-verification-radio-box" for="updateAddressNo">
        <p class="header">You Entered</p>
        <p class="line-item">
          {{ selectedMailingAddress.line1 }}
          {{ selectedMailingAddress.line2 }}
        </p>
        <p class="line-item">
          {{ selectedMailingAddress.city }},
          {{ selectedMailingAddress.state_province_region }}
          {{ selectedMailingAddress.zip_postal_code }}
        </p>
      </label>

      <input
        id="updateAddressYes"
        type="radio"
        name="updateAddress"
        class="updateAddress"
        value="yes"
      >

      <label class="address-verification-radio-box" for="updateAddressYes">
        <p class="header">USPS Verified</p>
        <p id="uspsAddress2" class="line-item">
          {{ uspsVerifiedAddress.line1 }}
          {{ uspsVerifiedAddress.line2 }}
        </p>
        <p class="line-item">
          <span id="uspsCity">
            {{ uspsVerifiedAddress.city }},
          </span>
          <span id="uspsState">
            {{ uspsVerifiedAddress.state_province_region }}
          </span>
          <span id="uspsZip">
            {{ uspsVerifiedAddress.zip_postal_code }}
          </span>
        </p>
      </label>
    </b-container>

    <b-container>
      <div class="mb-4 mt-2 text-center">
        <b-button
          variant="primary"
          class="mr-3"
          :class="$mq !== 'sm' ? 'col-sm-6' : 'col-sm-12'"
          @click="stepComplete()"
        >
          Continue
        </b-button>
      </div>
    </b-container>
  </b-form>
  <ct-centered-spinner v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BackButton from '../../shared/PaymentModals/BackButton'
import store from '@/store'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'

export default {
  name: 'Step2',
  components: {
    BackButton,
    CtCenteredSpinner,
  },
  data: function () {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('requestDocument', ['documents', 'selectedMailingAddress', 'uspsVerifiedAddress']),
  },
  methods: {
    ...mapActions('requestDocument', ['setSelectedMailingAddress']),
    async stepComplete () {
      this.loading = true
      let inputValue = document.querySelector('input[name="updateAddress"]:checked').value
      const updateMailingAddress = inputValue == 'yes'

      if (updateMailingAddress) {
        let updatedAddress = this.selectedMailingAddress

        Object.assign(updatedAddress,
          { line1: this.uspsVerifiedAddress.line1 },
          { line2: this.uspsVerifiedAddress.line2 },
          { city: this.uspsVerifiedAddress.city },
          { state_province_region: this.uspsVerifiedAddress.state_province_region },
          { zip_postal_code: this.uspsVerifiedAddress.zip_postal_code }
        )
        await store.dispatch('account/updatePersonAddress', updatedAddress)
        this.setSelectedMailingAddress(updatedAddress)
      }

      this.$emit('completed', updateMailingAddress)
      this.loading = false
    },
    async back() {
      this.$emit('back')
    },
  },
}

</script>

<style lang="scss" scoped>
#addr-verification-text {
  font-size: 18px;
  font-weight: 700;
}

.address-verification-radio-box {
  background: #eff8ff;
  padding: 15px 20px;
  border-radius: 10px;
  margin-bottom: 20px !important;
  display: block;
  text-transform: none;
  border: 3px solid #eff8ff;
  position: relative;
  cursor: pointer;

  &:before {
    content: '\2610';
    position: absolute;
    font-size: 28px;
    top: 10px;
    right: 20px;
    color: #31bfbb;
  }

  .header {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0px;
  }

  .line-item {
    margin-bottom: 0px;
  }
}

.updateAddress {
  position: absolute;
  opacity: 0;

  &:checked + .address-verification-radio-box {
    border-color: #31bfbb;

    &:before {
      content: '\2713';
    }
  }
}
</style>
