<template>
  <div v-if="!loading">
    <form v-if="type == 'add'" ref="form" @submit.prevent="saveContact">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <p class="text-center">
          <em>This will add a new contact to your account.</em>
        </p>
        <b-form-row>
          <b-col class="col-6">
            <b-form-group label="First Name:">
              <validation-provider v-slot="{ errors }" rules="required" name="Name">
                <b-form-input
                  v-model="currentContact.first_name"
                  placeholder="Enter first name"
                  type="text"
                  :state="errors.length > 0 ? !errors[0] : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group label="Last Name:">
              <validation-provider v-slot="{ errors }" rules="required" name="Name">
                <b-form-input
                  v-model="currentContact.last_name"
                  placeholder="Enter last name"
                  type="text"
                  :state="errors.length > 0 ? !errors[0] : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col class="col-6">
            <b-form-group label="Email:">
              <validation-provider v-slot="{ errors }" rules="required|email" name="Email">
                <b-form-input
                  v-model="currentContact.person_emails[0].email_address"
                  placeholder="Enter an email"
                  type="text"
                  :state="errors.length > 0 ? !errors[0] : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group label="Phone number:">
              <validation-provider v-slot="{ errors }" rules="required|phone_number" name="Phone">
                <b-form-input
                  v-model="currentContact.person_phones[0].phone_number"
                  v-mask="'###########'"
                  class="mb-1"
                  placeholder="Enter a phone number"
                  :state="errors.length > 0 ? !errors[0] : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form-row>

        <address-form
          :edit-address="currentContact.person_addresses[0]"
          :allow-existing-addresses="false"
          :show-contact-name="false"
          kind="mailing"
          style="padding:0 !important"
          @changed="addressInfoChanged"
        />

        <b-form-row class="justify-content-center">
          <div id="btn-submit">
            <button class="btn btn-primary submit-btn-width" type="submit" :disabled="invalid" style="pointer-events: auto">
              Continue
            </button>
          </div>
        </b-form-row>
        <b-tooltip v-if="invalid" target="btn-submit" placement="top" triggers="hover focus">
          Please fill out the rest of the form.
        </b-tooltip>
      </validation-observer>
    </form>

    <form v-else @submit.prevent="updateContact">
      <p class="text-center">
        <em>This will update the contacts address on your account.</em>
      </p>
      <address-form
        :edit-address="selectedAddress"
        :show-contact-name="true"
        :contact-name-readonly="true"
        style="padding:0 !important"
        @changed="addressInfoChanged"
      />
      <b-form-row class="justify-content-center">
        <div id="btn-submit-edit">
          <button class="btn btn-primary submit-btn-width" type="submit" style="pointer-events: auto">
            Continue
          </button>
        </div>
      </b-form-row>
    </form>
  </div>
  <ct-centered-spinner v-else />
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import AddressForm from '../shared/forms/AddressForm'
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from '../shared/CtCenteredSpinner'

export default {
  name: 'DocRequestAddressForm',
  components: {
    AddressForm,
    ValidationObserver,
    CtCenteredSpinner,
  },
  props: {
    selectedAddress: null,
    type: String,
  },
  data() {
    return {
      address: {},
      loading: false,
    }
  },
  computed: {
    ...mapGetters('account', ['currentContact', 'people']),
    editMode() {
      return !!this.selectedAddress
    },
    isInternational() {
      return this.currentContact.person_addresses[0].country !== 'US'
    },
  },
  beforeMount() {
    this.setCurrentContact({ contact: this.getEmptyContact() })
  },
  methods: {
    ...mapActions('account', ['createDocumentRequestContact', 'setCurrentContact', 'updatePerson', 'updatePersonAddress']),
    addressInfoChanged(address) {
      this.currentContact.person_addresses[0] = address
      this.address = address
    },
    newAddress() {
      return {
        contact_name: null,
        line1: null,
        line2: null,
        zip_postal_code: null,
        city: null,
        state_province_region: null,
        country: 'US',
        kind: 'primary',
      }
    },
    async saveContact() {
      const valid = await this.$refs.observer.validate()

      if (!valid) {
        return
      }

      this.loading = true

      try {
        const addressCopy = { ...this.currentContact.person_addresses[0] }

        if (this.isInternational) {
          // for non-US countries that don't use these fields since address table won't allow null
          this.handleNullStateProvinceRegion(addressCopy)
          this.handleNullZipPostalCode(addressCopy)
        }

        const params = {
          id: this.currentContact.id,
          first_name: this.currentContact.first_name,
          last_name: this.currentContact.last_name,
          person_emails_attributes: this.currentContact.person_emails,
          person_phones_attributes: this.currentContact.person_phones,
          person_addresses_attributes: [addressCopy],
        }

        let response = await this.createDocumentRequestContact(params)

        if (response.data.success) {
          this.address = response.data.result.person_addresses[0]
          this.$emit('completed', this.address)
        } else {
          this.makeToast('danger', 'Error', 'Error creating contact')
        }
      } catch (error) {
        this.makeToast('danger', 'Error', error.response.data.error.message)
      }
      this.loading = false
    },
    async updateContact() {
      this.loading = true

      try {
        const addressCopy = { ...this.address }

        if (this.isInternational) {
          // for non-US countries that don't use these fields since address table won't allow null
          this.handleNullStateProvinceRegion(addressCopy)
          this.handleNullZipPostalCode(addressCopy)
        }

        await this.updatePersonAddress(addressCopy)
      }
      catch (error) {
        this.makeToast('danger', 'Error', error.response.data.error.message)
      }
      this.loading = false
      this.$emit('completed', this.address)
    },
    getEmptyContact() {
      return {
        first_name: '',
        last_name: '',
        person_emails: [
          {
            email_address: '',
            kind: 'primary',
          },
        ],
        person_phones: [
          {
            phone_number: this.phoneNumber || '',
            kind: 'primary',
          },
        ],
        person_addresses: [
          this.newAddress(),
        ],
      }
    },
    handleNullStateProvinceRegion(address) {
      if (!address.state_province_region) {
        address.state_province_region = address.city
      }
    },
    handleNullZipPostalCode(address) {
      if (!address.zip_postal_code) {
        address.zip_postal_code = '00000'
      }
    },
    makeToast(variant, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      })
    },
  },
}
</script>
