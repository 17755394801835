var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "learn-more-documents-modal",
      attrs: {
        id: "learn-more-documents-modal",
        "title-tag": "h3",
        centered: "",
        size: "lg",
        "hide-footer": "",
        "hide-header": "",
      },
    },
    [
      _c(
        "h3",
        {
          staticClass: "text-center",
          staticStyle: { margin: "0", padding: "0" },
        },
        [_vm._v("\n    Learn More - Documents\n  ")]
      ),
      _c("br"),
      _c("div", { staticClass: "mb-2 text-left" }, [
        _c("h5", { staticClass: "sub-title" }, [
          _vm._v("\n      Click To Request\n    "),
        ]),
        _c("hr"),
        _c("ul", { staticClass: "help-content-list" }, [
          _c("li", [
            _vm._v("\n        A "),
            _c("b", [_vm._v("physical copy")]),
            _vm._v(
              " of the document may be requested up to 50 days from receipt. Documents after 50 days are securely destroyed and can no longer be requested.\n      "
            ),
          ]),
          _c("li", [
            _vm._v(
              "\n        Some document types cannot be requested as they were received as digital copies, and can simply be downloaded and printed from your portal.\n      "
            ),
          ]),
          _c("li", [
            _vm._v(
              "\n        For requests that cannot be completed, consider downloading and/or printing the document instead.\n      "
            ),
          ]),
          _c("li", [
            _vm._v(
              "\n        To request multiple documents in one request check the left side box on eligible documents and click the Bundle Multiple Docs button.\n      "
            ),
          ]),
          _c("li", [
            _vm._v(
              "\n        Requests may require additional charges based off weight and dimensions. Once these fees are calculated an invoice will be generated and you will be notified via email when it is available for payment.\n      "
            ),
          ]),
          _c("li", [
            _vm._v(
              "\n        Processing time may vary. If you require an urgent shipment, please contact us.\n      "
            ),
          ]),
        ]),
      ]),
      _c("br"),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "b-button",
            {
              staticClass: "mr-2",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.hidePopup()
                },
              },
            },
            [_vm._v("\n      Close\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }